<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.views.role', 1, true) }}
    </hero-bar>

    <section class="section is-main-section capitalize">
      <b-button tag="router-link"
                :to="{name: 'roles.create'}"
                type="is-primary" icon-left="plus">{{ $getTranslation('form.general.add') }} {{ $getTranslation('general.views.role', 0) }}</b-button>
    </section>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced" icon="account-multiple">
          <div v-if="!loading">

            <app-table
            :columns="columns"
            :page="page"
            :total="total"
            :editRoute="editRoute"
            :deleteRoute="endpoint"
            @onPageChange="onPageChange"
            :pagination-top="true"
            :pagination-bottom="true"
          />

          </div>
          <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>
    </section>
  </div>
</template>

<script>
import { ApiRoles, ApiPermissions } from "@/api-routes.js"
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'roles',
  mixins: [ListTemplateMixin],
  components: {
    'app-table': Table
  },

  data () {
    return {
      endpoint: this.$route.meta.type === 'roles' ? ApiRoles : ApiPermissions,
      moduleName: this.$route.meta.type,
      editRoute: 'roles.edit',
      total: 0,
      page: 1,
      // perPage: 15,
      // Table
      columns: {'name':'form', 'slug':'form', 'description':'form', 'created':'general', 'updated':'general', 'deleted':'general'}
    }
  },

  created () {
    this.fetchPaginatedItems ()
    // console.log('Roles created')
  }
}
</script>
